module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://factoryoflook.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Factory of Look","short_name":"FOL","description":"A Graphic Design Agency turning ideas into beautiful things. Outsource your design to our creative team of designers.","lang":"pl","start_url":"/","background_color":"#942985","theme_color":"#942985","display":"standalone","icon":"src/assets/images/fol-favicon.png","theme_color_in_head":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-157831222-1","head":true,"anonymize":true},
    }]
